"use strict";

// Class definition
var KTFreshFarm = function() {
  // Private functions
  var init = function() {

    $(".zox-fly-but-click").on('click', function() {
      $("#zox-fly-wrap").toggleClass("zox-fly-menu");
      $("#zox-fly-wrap").toggleClass("zox-fly-shadow");
      $(".zox-fly-but-wrap").toggleClass("zox-fly-open");
      $(".zox-fly-fade").toggleClass("zox-fly-fade-trans");
      $("#zox-site-main").toggleClass("zox-body-blur");
    });

    // Back to Top Button
    var duration = 500;
    $('.back-to-top').on('click', function(event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, duration);
      return false;
    });

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });

    // Search Toggle
    $(".zox-search-click").on('click', function() {
      $("#zox-search-wrap").toggleClass("zox-search-toggle");
      $("#zox-site-main").toggleClass("zox-body-blur");
      $("#zox-search-input").focus();
    });

    // Mobile Social Toggle
    $(".zox-mob-soc-click").on('click', function() {
      $("#zox-soc-mob-wrap").toggleClass("zox-soc-mob-more");
    });

    // Comments Toggle
    $(".zox-com-click").on('click', function() {
      $("#comments").show();
      $("#disqus_thread").show();
      $("#zox-comments-button").hide();
    });

    // Widget Columns Toggle
    $('.zox-widget-tab-wrap').each(function() {
      $(this).find(".zox-tab-col-cont").hide(); //Hide all content
      $(this).find("ul.zox-widget-tab-head li.zox-widget-tab-act").addClass("active").show(); //Activate first tab
      $(this).find(".zox-tab-col-cont:first").show(); //Show first tab content
    });

    $("ul.zox-widget-tab-head li").on('click', function(e) {
      $(this).parents('.zox-widget-tab-wrap').find("ul.zox-widget-tab-head li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(this).parents('.zox-widget-tab-wrap').find(".zox-tab-col-cont").hide(); //Hide all tab content

      var activeTab = $(this).find("a").attr("href"); //Find the href attribute value to identify the active tab + content
      $(this).parents('.zox-widget-tab-wrap').find(activeTab).fadeIn(); //Fade in the active ID content

      e.preventDefault();
    });

    $("ul.zox-widget-tab-head li a").on('click', function(e) {
      e.preventDefault();
    });

    // Night Mode
    $("#zox-site").toggleClass(localStorage.toggled);
		$(".zox-night-mode").on("click", function(){
			if (localStorage.toggled != "zox-dark") {
				$("#zox-site").toggleClass("zox-dark", true);
				localStorage.toggled = "zox-dark";
			} else {
				$("#zox-site").toggleClass("zox-dark", false);
				localStorage.toggled = "";
			}
		});

    // Leaderboard
    $(window).load(function(){
  		var leaderHeight = $("#zox-lead-top").outerHeight();
  		$("#zox-lead-top-wrap").css("height", leaderHeight );
    });


    $(window).resize(function(){
      var leaderHeight = $("#zox-lead-top").outerHeight();
      $("#zox-lead-top-wrap").css("height", leaderHeight );
    });

  }

  return {
    init: function() {
      init();
    }
  };
}();


$(document).on('turbolinks:load', function() {
  KTFreshFarm.init();
  gtag('config', 'UA-6335880-5', {'page_location': event.data.url});
});
